const defaultSettings = [
  { title: "id", checked: true },
  { title: "title", checked: true },
  { title: "type", checked: true },
  { title: "impressions", checked: true },
  { title: "visible", checked: true },
  { title: "clicks", checked: true },
  { title: "goals", checked: true },
  { title: "assisted_goals", checked: true },
  { title: "ctr", checked: true },
];

export default {
  state: {
    statsSettings: {
      campaigns: {
        url: "/get_campaign_statistics",
        urlByDate: "/get_campaign_statistics_by_date",
        settings: [...defaultSettings],
      },
      ad: {
        url: "/get_creative_statistics",
        urlByDate: "/get_creative_statistics_by_date",
        settings: [...defaultSettings],
      },
      tag: {
        url: "/get_tag_statistics",
        urlByDate: "/get_tag_statistics_by_date",
        settings: [
          { title: "id", checked: true },
          { title: "title", checked: true },
          { title: "size", checked: true },
          { title: "type", checked: true },
          { title: "impressions", checked: true },
          { title: "visible", checked: true },
          { title: "clicks", checked: true },
          { title: "goals", checked: true },
          { title: "assisted_goals", checked: true },
          { title: "ctr", checked: true },
        ],
      },
      size: {
        url: "/get_size_statistics",
        urlByDate: "/get_size_statistics_by_date",
        settings: [
          { title: "id", checked: true },
          { title: "size", checked: true },
          { title: "type", checked: true },
          { title: "impressions", checked: true },
          { title: "visible", checked: true },
          { title: "clicks", checked: true },
          { title: "goals", checked: true },
          { title: "assisted_goals", checked: true },
          { title: "ctr", checked: true },
        ],
      },
      goals: {
        url: "/get_goal_statistics",
        urlByDate: "/get_goal_statistics_by_date",
        settings: [
          { title: "date", checked: true },
          { title: "banner_id", checked: true },
          { title: "campaign_id", checked: true },
          { title: "goal_id", checked: true },
          { title: "banner_title", checked: true },
          { title: "campaign_title", checked: true },
          { title: "campaign_type", checked: true },
          { title: "goal_title", checked: true },
          { title: "count", checked: true },
          { title: "count_assisted", checked: true },
          { title: "count_unique", checked: true },
          { title: "price", checked: true },
        ],
      },
      period: {
        url: "/get_period_statistics",
        urlByDate: "/get_period_statistics",
        settings: [
          { title: "date", checked: true },
          { title: "impressions", checked: true },
          { title: "visible", checked: true },
          { title: "clicks", checked: true },
          { title: "goals", checked: true },
          { title: "assisted_goals", checked: true },
          { title: "ctr", checked: true },
        ],
      },
    },
  },
  getters: {
    statsSettings: (s) => s.statsSettings,
  },
  mutations: {
    setStatsSettings(s, { present, settings }) {
      s.statsSettings[present].settings = [];
      settings.forEach((item) => {
        s.statsSettings[present].settings.push({ ...item });
      });
    },
  },
};
