import axios from "axios";

export default axios.create({
  baseURL: "https://api.varyads.com/v1",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + localStorage.token ?? "",
    "x-authorization-entity": localStorage.token ? "session" : "",
  },
});
