import api from "@/axios/api";

export default {
  state: {
    goals: [],
  },
  getters: {
    goals: (s) => s.goals,
  },
  mutations: {
    setGoals(s, goals) {
      s.goals = goals;
    },
    deleteGoal(s, id) {
      const index = s.goals.findIndex((g) => g.id == id);
      s.goals.splice(index, 1);
    },
    addGoal(s, data) {
      s.goals.push(data);
    },
  },
  actions: {
    async addGoal({ commit }, data) {
      return api
        .post("/create_goal", data)
        .then((response) => {
          console.log("create_goal", response.data);
          data.id = response.data.id;
          data.code = response.data.code;
          data.date = new Date();
          commit("addGoal", data);

          return data;
        })
        .catch((error) => {
          console.log("create_goal error", error);

          return Promise.reject(error);
        });
    },
    async getGoals({ commit }) {
      return api
        .get("/list_goals")
        .then((response) => {
          console.log(response.data);
          let goals = [];
          response.data.items.forEach((goal) => {
            goal.date = new Date(goal.create);
            goals.push(goal);
          });
          commit("setGoals", goals);
        })
        .catch((error) => {
          console.log("create_goal error", error);

          return Promise.reject(error);
        });
    },
    async deleteGoal({ commit }, id) {
      return api
        .post(`/goal/${id}/delete`)
        .then((response) => {
          console.log(response.data);
          commit("deleteGoal", id);

          return response;
        })
        .catch((error) => {
          console.log("create_goal error", error);

          return Promise.reject(error);
        });
    },
  },
};
