import api from "@/axios/api";

export default {
  state: {
    campaigns: [],
  },
  getters: {
    campaigns: (s) =>
      s.campaigns.filter((campaign) => campaign.status != "deleted"),
    campaignsAll: (s) => s.campaigns,
    campaignsLoadAds: (s) =>
      s.campaigns.filter((c) => c.loadAds).map((c) => String(c.id)),
  },
  mutations: {
    setCampaigns(s, campaigns) {
      s.campaigns = campaigns;
    },
    duplicateCampaign(s, { id, newId, date }) {
      const campaign = { ...s.campaigns.find((c) => c.id == id) };
      campaign.id = newId;
      campaign.title += ` - copy ${date}`;
      s.campaigns.push(campaign);
    },
    deleteCampaign(s, id) {
      const index = s.campaigns.findIndex((c) => c.id == id);
      s.campaigns.splice(index, 1);
    },
    addCampaign(s, data) {
      s.campaigns.unshift({
        id: data.id,
        count_creatives: 0,
        title: data.title,
        type: data.type,
        sizes: [],
        settings: {
          type: "actions",
          declaration: 75,
          percentage: 60,
        },
        tags: [],
      });
    },
    updateSettings(s, data) {
      s.campaigns.find((c) => c.id == data.id).settings = data.settings;
    },
    setTags(s, data) {
      const index = s.campaigns.findIndex((c) => c.id == data.campaignId);
      s.campaigns[index].tags = [...data.tags];
    },
    addTag(s, data) {
      s.campaigns.find((c) => c.id == data.campaignId).tags.push(data.tag);
    },
    deleteTag(s, data) {
      const campaign = s.campaigns.find((c) => c.id == data.campaignId);
      const index = campaign.tags.findIndex((c) => c.id == data.tagId);
      campaign.tags.splice(index, 1);
    },
    addCountCampaign(s, id) {
      s.campaigns.find((c) => c.id == id).count_creatives++;
    },
    minusCountCampaign(s, id) {
      s.campaigns.find((c) => c.id == id).count_creatives--;
    },
    updateCampaignPreviews(s, id) {
      const campaign = s.campaigns.find((c) => c.id == id);
      const ads = this.getters.ads.filter((ad) => ad.campaignId == id);
      console.log(campaign, ads);

      let previews = [];
      for (let i in ads) {
        let preview = ads[i].preview;
        if (preview) previews.push(preview);
        if (previews.length == 2) break;
      }
      campaign.previews = previews;
    },
    updateCampaign(s, { campaignId, data }) {
      const campaign = s.campaigns.find((c) => c.id == campaignId);
      if (data.title !== undefined) campaign.title = data.title;
      if (data.top_type !== undefined) campaign.settings.type = data.top_type;
      if (data.top_limit !== undefined)
        campaign.settings.declaration = data.top_limit;
      if (data.top_percent !== undefined)
        campaign.settings.percentage = data.top_percent;
      if (data.default_url !== undefined)
        campaign.settings.defaultUrl = data.default_url;
    },
    campaignLoadAds(s, campaignId) {
      const campaign = s.campaigns.find((c) => c.id == campaignId) ?? {};
      campaign.loadAds = true;
    },
  },
  actions: {
    async getCampaigns({ commit }) {
      return api
        .get("/list_campaigns", {
          status: ["active", "paused", "deleted"],
        })
        .then((response) => {
          console.log("list_campaigns", response.data);
          let campaigns = [];
          for (let id in response.data.items) {
            let campaign = response.data.items[id];
            campaign.settings = {
              type: campaign.top_type,
              declaration: campaign.top_limit,
              percentage: campaign.top_percent,
              defaultUrl: campaign.default_url,
            };
            campaign.tags = [];
            campaign.loadAds = false;
            campaigns.push(campaign);
          }

          campaigns.sort((a, b) => {
            if (a.id < b.id) return 1;
            if (a.id > b.id) return -1;
            return 0;
          });

          commit("setCampaigns", campaigns);
        })
        .catch((error) => {
          console.log("list_campaigns error", error);
        });
    },
    async getTags({ commit }, id) {
      return api
        .get(`/campaign/${id}/list_tags`)
        .then((response) => {
          console.log("list_tags", response.data);
          let tags = [];
          for (let id in response.data.items) {
            let tag = response.data.items[id];
            tag.date = new Date(tag.create);
            tags.push(tag);
          }
          commit("setTags", {
            campaignId: id,
            tags,
          });
        })
        .catch((error) => {
          console.log("list_tags error", error);
        });
    },
    async addTag({ commit }, data) {
      return api
        .post(`/campaign/${data.campaignId}/create_tag`, data.tag)
        .then((response) => {
          console.log("create_tag", response.data);
          data.tag.id = response.data.id;
          data.tag.date = new Date();
          data.tag.code = response.data.code;
          commit("addTag", data);

          return response;
        })
        .catch((error) => {
          console.log("create_tag error", error);
          return Promise.reject(error);
        });
    },
    async deleteTag({ commit }, { campaignId, tagId }) {
      return api
        .post(`/tag/${tagId}/delete`)
        .then((response) => {
          console.log("create_tag", response.data);
          commit("deleteTag", { campaignId, tagId });

          return response;
        })
        .catch((error) => {
          console.log("create_tag error", error);
          return Promise.reject(error);
        });
    },
    async deleteCampaign({ commit }, id) {
      return api
        .post(`/campaign/${id}/delete`)
        .then((response) => {
          console.log("delete campaign", response.data);
          commit("deleteCampaign", id);
        })
        .catch((error) => {
          console.log("delete campaign error", error);
        });
    },
    async duplicateCampaign({ commit }, { id, date }) {
      return api
        .post("/copy_campaigns", { ids: [id] })
        .then((response) => {
          console.log("copy_campaigns", response.data);
          commit("duplicateCampaign", {
            id,
            newId: response.data.ids[0],
            date,
          });
        })
        .catch((error) => {
          console.log("copy_campaigns error", error);
        });
    },
    async updateCampaign({ commit }, { campaignId, data }) {
      return api
        .post(`/campaign/${campaignId}/update`, data)
        .then((response) => {
          console.log("campaign update", response.data);
          commit("updateCampaign", { campaignId, data });

          return response;
        })
        .catch((error) => {
          console.log("campaign update error", error);
          return Promise.reject(error);
        });
    },
  },
};
