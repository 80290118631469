<template>
  <router-link v-if="link" :to="link" class="menu-item">
    <img :src="$baseUrl + 'img/icons/' + icon" />
    <span><slot></slot></span>
  </router-link>
  <a href="#" v-else-if="func" @click.prevent="func" class="menu-item">
    <img :src="$baseUrl + 'img/icons/' + icon" />
    <span><slot></slot></span>
  </a>
</template>

<script>
export default {
  props: {
    icon: String,
    link: {
      required: false,
    },
    func: {
      required: false,
    },
  },
};
</script>

<style scoped>
.menu-item {
  padding: 5px 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
}
.menu-item:hover {
  background-color: #919eab3d;
}
.menu-item img {
  min-width: 16px;
  max-width: 16px;
}
.menu-item span {
  color: #212b36;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  white-space: nowrap;
}
</style>
