import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes.js";
import store from "@/store";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  routes,
});

router.beforeEach((to, from, next) => {
  let currentUser = !!localStorage.token;
  let isRequireAuth = to.meta?.auth || false;
  let isRequireNoAuth = to.meta?.noauth || false;
  if (isRequireAuth && !currentUser) {
    next("/login");
  } else if (isRequireNoAuth && currentUser) {
    next("/");
  } else {
    if (["en", "ru"].indexOf(to.params.lang) !== -1) {
      localStorage.setItem("lang", to.params.lang);
    }
    if (to.meta.titleKey != from.meta.titleKey)
      store.commit("setTitleKey", to.meta.titleKey);

    // store.commit("setLocale", localStorage.lang || "en");

    next();
  }
});

export default router;
