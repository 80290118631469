<template>
  <div class="main-layout">
    <AppNavbar />

    <div class="main-layout__content">
      <router-view v-slot="{ Component, route }">
        <Transition appear>
          <div :key="route.name">
            <component :is="Component" />
          </div>
        </Transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import AppNavbar from "@/components/App/AppNavbar";
import BackLink from "@/components/BackLink";

export default {
  name: "MainLayout",
  components: { AppNavbar, BackLink },
};
</script>

<style scoped>
.main-layout {
  min-height: 100vh;
}
.main-layout__content {
  padding: 24px 40px;
}
@media (max-width: 700px) {
  .main-layout__content {
    padding: 20px;
  }
}

:deep(.title) {
  color: #212b36;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}
</style>
