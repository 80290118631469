<template>
  <div class="guest-layout">
    <GuestNavbar v-if="!isLogin" />
    <AppNavbar v-else />

    <div class="guest-layout__content">
      <router-view v-slot="{ Component, route }">
        <Transition appear>
          <div :key="route.name">
            <component :is="Component" />
          </div>
        </Transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import GuestNavbar from "@/components/GuestNavbar";
import AppNavbar from "@/components/App/AppNavbar";

export default {
  components: { GuestNavbar, AppNavbar },
  computed: {
    isLogin() {
      return !!localStorage.token;
    },
  },
};
</script>

<style scoped>
.guest-layout {
  min-height: 100vh;
}
.guest-layout__content {
  padding: 24px 40px;
}
@media (max-width: 700px) {
  .guest-layout__content {
    padding: 20px;
  }
}
</style>
