import store from "@/store";
import en from "@/locales/en.json";
import ru from "@/locales/ru.json";

const locales = {
  en,
  ru,
};

export default function translate(key) {
  const locale = store.getters.getLocale || "en";
  return locales[locale]?.[key] || "Error locale";
}
