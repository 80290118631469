<template>
  <nav class="nav">
    <div class="nav__links">
      <router-link to="/" class="nav__logo">
        <img src="@/assets/img/logo-small.png" alt="Vary Ads" />
      </router-link>
      <router-link
        v-for="link in links"
        :key="link.url"
        class="nav__link"
        :to="link.url"
      >
        {{ this.$t(link.nameKey) }}
      </router-link>
    </div>
    <div class="nav__buttons">
      <SelectLang style="display: none" />
      <ProfileMenu />
    </div>
  </nav>
</template>

<script>
import SelectLang from "@/components/SelectLang";
import ProfileMenu from "@/components/ProfileMenu";

export default {
  name: "AppNavbar",
  components: { SelectLang, ProfileMenu },
  data: function () {
    return {
      links: [
        {
          nameKey: "campaigns",
          url: "/",
        },
        {
          nameKey: "statistics",
          url: "/stats",
        },
      ],
    };
  },
};
</script>

<style scoped>
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  border-bottom: 1px solid #919eab3d;
}
.nav__links {
  display: flex;
  align-items: center;
  gap: 24px;
}
.nav__logo {
  width: 154px;
  margin-right: 44px;
}
.nav__logo img {
  display: block;
}
.nav__link {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #637381;
  transition: 0.2s;
  padding: 20px 2px;
  /* display: inline-block; */
}
.nav__link:hover {
  color: #212b36;
}
.nav__link.active {
  color: #212b36;
  position: relative;
}
.nav__link.active::after {
  content: "";
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  border-radius: 10px;
  background-color: #0088ff;
  transition: 0.2s;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: showLine 0.2s both;
}
@keyframes showLine {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.nav__buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 700px) {
  .nav {
    padding: 12px 20px;
  }
  .nav__link {
    display: none;
  }
}
</style>
