import api from "@/axios/api";

export default {
  state: {
    userData: {
      firstName: "",
      lastName: "",
      email: "",
    },
  },
  getters: {
    userData: (s) => s.userData,
  },
  mutations: {
    clearUserData(s) {
      s.userData = {
        firstName: "",
        lastName: "",
        email: "",
      };
    },
    updateUserData(s, data) {
      const curData = s.userData;
      s.userData = { ...curData, ...data };
    },
  },
  actions: {
    async getProfile({ commit }) {
      return api
        .get("/get_profile")
        .then((response) => {
          console.log("get_profile", response.data);

          commit("updateUserData", {
            firstName: response.data.name,
            lastName: response.data.surname,
            email: response.data.email,
          });
        })
        .catch((error) => {
          console.log("list_campaigns error", error);
        });
    },
    async updateUserData({ commit }, data) {
      return api
        .post("/update_profile", data)
        .then((response) => {
          console.log("update_profile", response);
          commit("updateUserData", {
            firstName: data.name,
            lastName: data.surname,
            email: data.email,
          });

          return response;
        })
        .catch((error) => {
          console.log("update_profile error", error);

          return Promise.reject(error);
        });
    },
  },
};
