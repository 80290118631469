export default {
  install(app) {
    app.config.globalProperties.$shareLink = function (ads) {
      const token = btoa(JSON.stringify(ads));

      const input = document.createElement("input");
      input.value = window.location.origin + this.$baseUrl + "share/" + token;

      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(input.value);

      document.body.removeChild(input);
    };
  },
};
