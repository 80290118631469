<template>
  <Teleport to="body">
    <div class="menu-list" :class="{ active: active }" :style="coords">
      <AppMenuItem
        v-for="item in list"
        :key="item.name"
        :icon="item.icon"
        :link="item.link ? item.link : false"
        :func="item.func ? item.func : false"
        :class="item.class"
      >
        {{ item.name }}
      </AppMenuItem>
    </div>
  </Teleport>
</template>

<script>
import AppMenuItem from "./AppMenuItem.vue";
export default {
  components: { AppMenuItem },
  props: {
    list: Array,
    active: {
      type: Boolean,
      default: () => false,
    },
    coords: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.menu-list {
  /* top: 100%;
  right: 50%; */
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(22, 28, 36, 0.2),
    0px 0px 2px 0px rgba(22, 28, 36, 0.1);
  padding: 4px 0;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  transform: scale(0);
  transform-origin: top right;
  z-index: 3;
}
.menu-list.active {
  transform: scale(1);
  right: 0;
}
@media (min-width: 701px) {
  .mobile-link {
    display: none;
  }
}
</style>
