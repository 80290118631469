export default {
  install(app) {
    app.config.globalProperties.$message = (text, icon, time = 1200) => {
      let wrapper = document.querySelector(".wrapper-message");
      if (!wrapper) {
        wrapper = document.createElement("div");
        wrapper.classList.add("wrapper-message");
        document.body.append(wrapper);
      }

      let baseUrl = process.env.BASE_URL;
      let newItem = document.createElement("div");
      newItem.classList.add("wrapper-message__item");
      newItem.innerHTML = `
        <div class="wrapper-message__icon">
          <img src="${baseUrl}img/icons/${icon}.svg">
        </div>
        <div class="wrapper-message__text">${text}</div>
      `;

      wrapper.append(newItem);

      setTimeout(() => {
        newItem.classList.add("remove");
        setTimeout(() => {
          newItem.parentNode.removeChild(newItem);
        }, 400);
      }, time);
    };
  },
};
