<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";
import GuestLayout from "@/layouts/GuestLayout";

import { mapGetters } from "vuex";

export default {
  components: {
    AuthLayout,
    MainLayout,
    GuestLayout,
  },
  computed: {
    ...mapGetters(["getMessages"]),
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },
  watch: {
    getMessages: {
      handler() {
        this.alertMessages();
      },

      deep: true,
    },
  },
  methods: {
    alertMessages() {
      let messages = this.getMessages;
      if (messages.length > 0) {
        messages.forEach((message) => {
          this.$message(message.message, message.icon);
        });
        this.$store.commit("setMessages", []);
      }
    },
  },
  mounted() {
    this.alertMessages();
  },
};
</script>

<style>
@import "~normalize.css/normalize.css";
@import "@/assets/css/style.css";
</style>
