export const routes = [
  {
    path: "/login/:lang?",
    name: "login",
    meta: {
      titleKey: "loginTitle",
      layout: "auth",
      noauth: true,
    },
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/forgot/:lang?",
    name: "forgot",
    meta: {
      titleKey: "forgotTitle",
      layout: "auth",
      noauth: true,
    },
    component: () => import("@/views/ForgotView.vue"),
  },
  {
    path: "/set-new-password",
    name: "set-new-password",
    meta: {
      titleKey: "forgotTitle",
      layout: "auth",
      auth: true,
    },
    component: () => import("@/views/SetNewPassword.vue"),
  },
  {
    path: "/register/:lang?",
    name: "register",
    meta: {
      titleKey: "registerTitle",
      layout: "auth",
      noauth: true,
    },
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/verify/:lang?",
    name: "verify",
    meta: {
      titleKey: "verifyTitle",
      layout: "auth",
      noauth: true,
    },
    component: () => import("@/views/VerifyView.vue"),
  },
  {
    path: "/verify-link/:lang?",
    name: "verify-link",
    meta: {
      titleKey: "verifyTitle",
      layout: "auth",
      noauth: true,
    },
    component: () => import("@/views/VerifyLinkView.vue"),
  },
  {
    path: "/confirm_email/:token",
    name: "confirmEmail",
    meta: {
      titleKey: "confirmEmail",
      layout: "auth",
      noauth: true,
    },
    component: () => import("@/views/ConfirmEmailView.vue"),
  },
  {
    path: "/recovery_password/:token",
    name: "recoveryPassword",
    meta: {
      titleKey: "recoveryPassword",
      layout: "auth",
      noauth: true,
    },
    component: () => import("@/views/recoveryPasswordView.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      titleKey: "profileTitle",
      layout: "main",
      auth: true,
    },
    component: () => import("@/views/ProfileView.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: {
      titleKey: "campaigns",
      layout: "main",
      auth: true,
    },
    component: () => import("@/views/CampaignsView.vue"),
  },
  {
    path: "/campaign/:id",
    name: "campaign",
    meta: {
      titleKey: "campaign",
      layout: "main",
      auth: true,
    },
    component: () => import("@/views/CampaignView.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/CampaignListView.vue"),
      },
      {
        path: "settings",
        component: () => import("@/views/CampaignSettingsView.vue"),
      },
      {
        path: "tags",
        component: () => import("@/views/CampaignTagsView.vue"),
      },
    ],
  },
  {
    path: "/campaign/:id/:adId",
    name: "ad",
    meta: {
      titleKey: "ad",
      layout: "main",
      auth: true,
    },
    component: () => import("@/views/AdView.vue"),
  },
  {
    path: "/goals",
    name: "goals",
    meta: {
      titleKey: "goals",
      layout: "main",
      auth: true,
    },
    component: () => import("@/views/GoalsView.vue"),
  },
  {
    path: "/stats/:campaignId?/:adId?",
    name: "stats",
    meta: {
      titleKey: "statistics",
      layout: "main",
      auth: true,
    },
    component: () => import("@/views/StatsView.vue"),
  },
  {
    path: "/share/:token",
    name: "share",
    meta: {
      titleKey: "share",
      layout: "guest",
    },
    component: () => import("@/views/ShareView.vue"),
  },
  {
    path: "/404",
    name: "notFound",
    meta: {
      title: "Page not found",
      layout: "auth",
    },
    component: () => import("@/views/NotFoundView.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
];
