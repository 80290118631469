import api from "@/axios/api";

export default {
  state: {
    ads: [],
  },
  getters: {
    ads: (s) => s.ads.filter((ad) => ad.status != "deleted"),
    adsAll: (s) => s.ads,
  },
  mutations: {
    setAds(s, ads) {
      s.ads = ads;
    },
    deleteAd(s, id) {
      const index = s.ads.findIndex((c) => c.id == id);
      s.ads.splice(index, 1);
    },
    moveAd(s, data) {
      const ad = s.ads.find((ad) => ad.id == data.id);
      const oldCampaignId = ad.campaignId;
      ad.campaignId = data.campaignId;

      this.commit("minusCountCampaign", oldCampaignId);
      this.commit("addCountCampaign", data.campaignId);

      this.commit("updateCampaignPreviews", oldCampaignId);
      this.commit("updateCampaignPreviews", data.campaignId);
    },
    copyAd(s, data) {
      const ad = { ...s.ads.find((c) => c.id == data.id) };
      ad.id = data.newId;
      ad.date = new Date();
      ad.title += ` - copy (${data.date})`;
      ad.campaignId = data.campaignId;
      s.ads.push(ad);
    },
    addAd(s, data) {
      s.ads.unshift({
        id: data.id,
        campaignId: data.campaignId,
        preview: data.preview,
        title: data.title,
        sizes: data.sizes,
        status: data.status ?? "active",
        date: new Date(),
      });
      this.commit("addCountCampaign", data.campaignId);
      this.commit("updateCampaignPreviews", data.campaignId);
    },
    addAds(s, data) {
      s.ads.unshift(...data);
    },
    setAdsCampaign(s, data) {
      const ads = s.ads.filter((ad) => ad.campaignId == data.campaign_id);
      ads.forEach((ad) => {
        this.commit("deleteAd", ad.id);
      });

      this.commit("addAds", data.ads);
    },
    changeStatusAd(s, data) {
      s.ads.find((ad) => ad.id == data.id).status = data.status;
    },
    updateAd(s, { id, data }) {
      const ad = s.ads.find((ad) => ad.id == id);

      for (let key in data) {
        switch (key) {
          case "campaign_id":
            continue;
          case "default_url":
            ad["defaultUrl"] = data[key];
            break;
          default:
            ad[key] = data[key];
            break;
        }
      }
    },
  },
  actions: {
    async getAds({ commit }, campaign_id) {
      return api
        .post(`/campaign/${campaign_id}/list_banners`, {
          status: ["active", "inactive", "deleted"],
        })
        .then((response) => {
          console.log("list_banners", response.data);
          let ads = [];
          if (response.data.items.length == 0) return;

          for (let id in response.data.items) {
            let ad = response.data.items[id];
            ad.campaignId = campaign_id;
            ad.date = new Date(ad.create);
            ad.sizes = response.data.items[id].sizes;
            ad.defaultUrl = response.data.items[id].default_url;
            ads.push(ad);
          }

          ads.sort((a, b) => {
            if (a.id < b.id) return 1;
            if (a.id > b.id) return -1;
            return 0;
          });

          commit("setAdsCampaign", { campaign_id, ads });
        })
        .catch((error) => {
          console.log("list_banners error", error);
        });
    },
    async deleteAds({ commit }, { campaign_id, arrIds }) {
      return api
        .post(`/campaign/${campaign_id}/delete_banners`, { ids: arrIds })
        .then((response) => {
          console.log("delete banner", response.data);
          arrIds.forEach((id) => {
            commit("deleteAd", id);
            commit("minusCountCampaign", campaign_id);
            commit("updateCampaignPreviews", campaign_id);
          });
        })
        .catch((error) => {
          console.log("delete banner error", error);

          return Promise.reject(error);
        });
    },
    async updateAd({ commit }, { id, data }) {
      return api
        .post(`/banner/${id}/update`, data)
        .then((response) => {
          console.log("banner update", response.data);
          commit("updateAd", { id, data });
          return response;
        })
        .catch((error) => {
          console.log("banner update error", error);
          return Promise.reject(error);
        });
    },
    async copyAds({ commit }, { campaignId, ids, data, date }) {
      return api
        .post(`/campaign/${campaignId}/copy_banners`, { ids, ...data })
        .then((response) => {
          console.log(response.data);
          ids.forEach((id, i) => {
            commit("copyAd", {
              id,
              newId: response.data.ids[i],
              campaignId: data.campaign_id,
              date,
            });
            commit("addCountCampaign", data.campaign_id);
            commit("updateCampaignPreviews", data.campaign_id);
          });

          return response;
        })
        .catch((error) => {
          console.log(error);
          return Promise.reject(error);
        });
    },
    async updateAds({ commit }, { campaignId, ids, data }) {
      return api
        .post(`/campaign/${campaignId}/update_banners`, { ids, ...data })
        .then((response) => {
          console.log("banner update", response.data);

          ids.forEach((id) => {
            commit("updateAd", { id, data });

            if (data.campaign_id) {
              commit("moveAd", {
                id,
                campaignId: data.campaign_id,
              });
            }
          });

          return response;
        })
        .catch((error) => {
          console.log("banner update error", error);
          return Promise.reject(error);
        });
    },
  },
};
