import { createStore } from "vuex";
import translate from "@/utils/translate";
import user from "./user";
import campaigns from "./campaigns";
import goals from "./goals";
import blanks from "./blanks";
import ads from "./ads";
import stats from "./stats";

export default createStore({
  state: {
    locale: "en",
    titleKey: "",
    messages: [],
  },
  getters: {
    getLocale: (s) => s.locale,
    getTitleKey: (s) => s.titleKey,
    getMessages: (s) => s.messages,
  },
  mutations: {
    setLocale(s, locale) {
      localStorage.setItem("lang", locale);
      s.locale = locale;
      document.title = translate(this.getters.getTitleKey);
    },
    setTitleKey(s, titleKey) {
      s.titleKey = titleKey;
    },
    addMessage(s, { icon, message }) {
      s.messages.push({ icon, message });
    },
    setMessages(s, messages) {
      s.messages = messages;
    },
  },
  actions: {},
  modules: { user, campaigns, goals, blanks, ads, stats },
});
