import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import moment from "moment";

import filters from "./filters";
import translate from "./utils/translate";

import messagePlugin from "./plugins/messagePlugin";
import shareLinkPlugin from "./plugins/shareLinkPlugin";

let app = createApp(App)
  .use(store)
  .use(router)
  .use(messagePlugin)
  .use(shareLinkPlugin);

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$t = translate;
app.config.globalProperties.$baseUrl = process.env.BASE_URL;
app.config.globalProperties.$moment = moment;

app.mount("#app");
