import api from "@/axios/api";

let baseUrl = process.env.BASE_URL;

export default {
  state: {
    categoriesBlank: {},
    blanks: {},
  },
  getters: {
    categoriesBlank: (s) => s.categoriesBlank,
    blanks: (s) => s.blanks,
  },
  mutations: {
    clearBlanks(s) {
      s.categoriesBlank = {};
      s.blanks = {};
    },
    addCategoriesBlank(s, { id, data }) {
      s.categoriesBlank[id] = data;
    },
    addBlank(s, { id, data }) {
      s.blanks[id] = data;
    },
  },
  actions: {
    async getCategoriesBlank({ commit }, id) {
      return api
        .get(`/campaign/${id}/list_creative_categories`)
        .then((response) => {
          console.log("list_creative_categories", response.data);
          commit("addCategoriesBlank", {
            id,
            data: [
              {
                id: 0,
                title: "All categories",
                icon: baseUrl + "img/categories/0.png",
              },
              ...response.data.items,
            ],
          });

          return response;
        })
        .catch((error) => {
          console.log("list_creative_categories error", error);

          return Promise.reject(error);
        });
    },
    async getBlanks({ commit }, id) {
      return api
        .get(`/campaign/${id}/list_creatives`)
        .then((response) => {
          console.log("list_creatives", response.data);
          commit("addBlank", {
            id,
            data: [
              {
                id: 0,
                category_ids: ["0"],
                sizes: [],
                preview: baseUrl + "img/blanks/0.png",
              },
              ...response.data.items,
            ],
          });

          return response;
        })
        .catch((error) => {
          console.log("list_creatives error", error);

          return Promise.reject(error);
        });
    },
  },
};
