<template>
  <router-link :to="link" class="link link-back">
    <img src="@/assets/img/icons/arr-left.svg" alt="<" />
    <span>{{ $t("back") }}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    link: String,
  },
};
</script>

<style scoped>
.link-back {
  display: inline-flex;
  align-items: center;
}
.link-back span {
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
}
</style>
