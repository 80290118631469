<template>
  <div class="lang-block">
    <div class="lang-block__select">
      <img
        :src="`${$baseUrl}img/flags/${selectLang}.png`"
        :alt="selectLang"
        class="flag-img"
      />
    </div>
    <div class="lang-block__list" :class="{ active: isActive }">
      <div
        v-for="key in listLang"
        :key="key"
        class="lang-block__item"
        @click="changeLang(key)"
      >
        <img
          :src="`${$baseUrl}img/flags/${key}.png`"
          :alt="key"
          class="flag-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectLang",
  data: () => ({
    listLang: ["en", "ru"],
    isActive: false,
  }),
  computed: {
    selectLang() {
      return this.$store.getters.getLocale || "en";
    },
  },
  methods: {
    changeLang(key) {
      this.$store.commit("setLocale", key);
    },
    toggleMenu(e) {
      if (!e.target.closest(".lang-block__select")) this.isActive = false;
      else this.isActive = !this.isActive;
    },
  },
  mounted() {
    document.querySelector("body").addEventListener("click", this.toggleMenu);
  },
  unmounted() {
    document
      .querySelector("body")
      .removeEventListener("click", this.toggleMenu);
  },
};
</script>

<style scoped>
.lang-block {
  position: relative;
}
.lang-block__select {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  padding: 6px;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.lang-block__select img {
  width: 100%;
}
.lang-block__select:hover {
  background-color: #919eab3d;
}
.lang-block__list {
  position: absolute;
  transition: 0.2s;
  transform: scale(0);
  transform-origin: top;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(22, 28, 36, 0.2),
    0px 0px 2px 0px rgba(22, 28, 36, 0.1);
  width: 50px;
  left: -5px;
  top: 100%;
}
.lang-block__list.active {
  transform: scale(1);
}
.lang-block__item {
  padding: 8px 10px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
}
.lang-block__item:hover {
  background-color: #919eab3d;
}
.flag-img {
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #ddd;
}
</style>
