<template>
  <div class="profile-menu" ref="menu">
    <div class="profile-menu__avatar">{{ avatar }}</div>
    <AppMenu :coords="coordsMenu()" :list="menuItems" :active="isActive" />
  </div>
</template>

<script>
import AppMenu from "./App/AppMenu.vue";

import api from "@/axios/api";

import { mapGetters } from "vuex";

export default {
  name: "ProfileMenu",
  components: { AppMenu },
  data() {
    return {
      isActive: false,
      menuItems: [
        {
          icon: "cards.svg",
          name: this.$t("campaigns"),
          link: "/",
          class: "mobile-link",
        },
        {
          icon: "stats.svg",
          name: this.$t("statistics"),
          link: "/stats",
          class: "mobile-link",
        },
        {
          icon: "profile.svg",
          name: this.$t("profile"),
          link: "/profile",
        },
        {
          icon: "logout.svg",
          name: this.$t("logout"),
          func: this.logout,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userData"]),
    avatar() {
      const name = this.userData.firstName || " ";
      const lastName = this.userData.lastName || " ";
      return name[0] + lastName[0];
    },
  },
  methods: {
    logout(e) {
      e.preventDefault();
      delete localStorage.token;
      api.defaults.headers["Authorization"] = "";
      api.defaults.headers["x-authorization-entity"] = "";
      this.$router.push("/login");

      // clear store data
      this.$store.commit("setAds", []);
      this.$store.commit("setCampaigns", []);
      this.$store.commit("setGoals", []);
      this.$store.commit("clearBlanks");
      this.$store.commit("clearUserData");
    },
    toggleMenu(e) {
      if (!e.target.closest(".profile-menu__avatar")) this.isActive = false;
      else this.isActive = !this.isActive;
    },
    coordsMenu() {
      let el = this.$refs.menu;
      if (!el) return;

      let rect = el.getBoundingClientRect();
      let top = window.scrollY + rect.bottom + 4 + "px";
      let right = document.body.clientWidth - rect.right + "px";
      return {
        top,
        right,
      };
    },
  },
  async mounted() {
    document.querySelector("body").addEventListener("click", this.toggleMenu);
    if (this.userData.firstName == "") this.$store.dispatch("getProfile");
  },
  unmounted() {
    document
      .querySelector("body")
      .removeEventListener("click", this.toggleMenu);
  },
};
</script>

<style scoped>
.profile-menu {
  position: relative;
}
.profile-menu__avatar {
  width: 40px;
  height: 40px;
  color: #637381;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 100px;
  background-color: #c4cdd5;
  padding: 9px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
